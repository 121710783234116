import { useQuery, useMutation } from "react-query";
import { workTasksService } from "../services/work-tasks.service";
import { CreateOrUpdateWorkTask } from "./models/create-or-update-work-task";
import { UpdateWorkTaskState } from "./models/update-work-task-state.model";
import { UpdateWorkTaskName } from "./models/update-work-task-name.model";
import { UpdateWorkTasksIndexesRequest } from "./models/update-work-tasks-indexes-request";

export function useActiveBoardWorkTasksQuery() {
  return useQuery(
    ["work-tasks"],
    () => workTasksService.getActiveBoardWorkTasks(),
    {
      refetchInterval: 60 * 1000,
    }
  );
}

export function useAddWorkTaskMutation() {
  return useMutation((request: CreateOrUpdateWorkTask) =>
    workTasksService.addWorkTask(request)
  );
}

export function useUpdateWorkTaskMutation() {
  return useMutation((request: CreateOrUpdateWorkTask) =>
    workTasksService.editWorkTask(request)
  );
}

export function useUpdateWorkTasksIndexesMutation() {
  return useMutation((request: UpdateWorkTasksIndexesRequest) =>
    workTasksService.updateWorkTasksIndexes(request)
  );
}

export function useUpdateTaskStateMutation() {
  return useMutation((request: UpdateWorkTaskState) =>
    workTasksService.updateWorkTaskState(request)
  );
}

export function useUpdateWorkTaskNameMutation() {
  return useMutation((request: UpdateWorkTaskName) =>
    workTasksService.updateWorkTaskName(request)
  );
}

export function useDeleteWorkTaskMutation() {
  return useMutation((id: number) => workTasksService.deleteWorkTask(id));
}

export function useInstallationWorkTasksQuery(installationId?: number) {
  return useQuery(
    ["work-tasks"],
    () => workTasksService.getWorkTasksByInstallation(installationId!),
    { enabled: !!installationId }
  );
}

export function useWorkTaskSummaryQuery(id?: number) {
  return useQuery(
    ["work-task", "summary", id],
    () => workTasksService.getWorkTaskSummary(id!),
    {
      enabled: !!id,
    }
  );
}
