import { useState, useCallback } from "react";
import { faUser, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDistance } from "date-fns";
import { NoteItem } from "../../../queries/models/note-item.model";
import { FileDownload } from "../FileDownload";
import { ImageWrapper } from "../ImageWrapper";
import { GalleryPreview } from "../GalleryPreview/gallery-preview";
import { CustomModal } from "../MobileModal/custom-modal";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useToast } from "../toast-context-provider";
import { useDeleteNoteItemMutation } from "../../../queries/notes.query";
import { authService } from "../../../services/auth.service";
import { AppFeatures } from "../../../queries/models/enums/app-feature-enum";

export function NoteItemComponent(item: NoteItem) {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();
  const deleteNoteItemMutation = useDeleteNoteItemMutation();
  const [activeIndex, setActiveIndex] = useState<number>();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const handleDelete = useCallback(async () => {
    const user = authService.getLoggedUser();

    if (
      authService.hasAccess(AppFeatures.GlobalWrite) ||
      item.userId === user?.id
    ) {
      const mutateOptions = {
        onSuccess: async () => {
          toast.current?.show({
            severity: "success",
            detail: t("alert.deleteSuccess"),
          });
          setDeleteModalVisible(false);
          await queryClient.invalidateQueries(["notes", item.noteId]);
        },
        onError: async (error: any) => {
          toast.current?.show({
            severity: "error",
            detail: error.data,
          });
        },
      };
      return await deleteNoteItemMutation.mutateAsync(item.id, mutateOptions);
    } else {
      toast.current?.show({
        severity: "warn",
        detail: t("common.youDoNotHaveEnoughPermissions"),
      });
    }
  }, [
    deleteNoteItemMutation,
    item.id,
    item.noteId,
    item.userId,
    queryClient,
    t,
    toast,
  ]);

  return (
    <>
      <div
        className="flex mx-2 mt-2"
        style={{ width: "calc(100% - 1rem)" }}
      >
        <div
          className="pt-2"
          style={{ width: "50px" }}
        >
          <div
            style={{
              width: "50px",
              height: "50px",
              fontSize: "25px",
              borderRadius: "100%",
              backgroundColor: "var(--primary-color)",
            }}
            className="justify-content-center align-items-center flex shadow-3"
          >
            <FontAwesomeIcon
              icon={faUser}
              style={{
                color: "white",
              }}
            />
          </div>
        </div>

        <div
          style={{
            width: "calc(100% - 50px - 0.5rem)",
            marginLeft: "0.5rem",
            backgroundColor: "#EDEDED",
            borderRadius: "12px",
          }}
          className="shadow-3"
        >
          <div className="flex justify-content-between">
            <div
              className="h-2rem grid grid-nogutter p-1"
              style={{ gridTemplateColumns: "auto 1fr" }}
            >
              <div className="h-full text-xl font-medium">
                {item.user?.username}
              </div>
              <div className="h-full text-sm ml-2 flex align-items-end">
                <div>
                  {`${formatDistance(new Date(item.date), new Date(), {
                    addSuffix: true,
                  })}`}
                </div>
              </div>
            </div>
            <div className="p-1">
              <div
                className="p-1 border-round-lg hover:bg-red-300 hover:text-white"
                onClick={() => setDeleteModalVisible(true)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </div>
            </div>
          </div>
          <div className="flex justify-between pb-1">
            <div className="pl-2">{item.message}</div>
            <div className="pl-2 pr-2 flex-grow-1 min-w-0">
              <div className="flex justify-content-end flex-wrap">
                {item.images && activeIndex !== undefined && (
                  <GalleryPreview
                    images={item.images}
                    activeIndex={activeIndex}
                    onItemChange={(e) => setActiveIndex(e)}
                    onClose={() => setActiveIndex(undefined)}
                  />
                )}

                {item.images &&
                  item.images.map((image, index) => {
                    let imgEl = (
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          setActiveIndex(index);
                        }}
                      >
                        <ImageWrapper
                          imageSrc={image.thumbnailSrc}
                          width="64px"
                          height="48px"
                        />
                      </div>
                    );

                    return <div key={index}>{imgEl}</div>;
                  })}
              </div>
              <div className="flex flex-column align-items-end pr-2">
                {item.files?.map((x) => (
                  <FileDownload
                    key={x.id}
                    file={x}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        isOpen={deleteModalVisible}
        header={t("common.confirmation")}
        onClose={() => setDeleteModalVisible(false)}
        centered
        justified
        height="max-content"
        body={t("dialog.deleteThisNoteItem")}
        confirmation
        onConfirm={handleDelete}
      />
    </>
  );
}
