import { WorkTaskSummary } from "../queries/models/work-task-summary.model";
import { jsPDF } from "jspdf";
import { TimeSpan } from "./timespan";
import autoTable from "jspdf-autotable";

export interface UserWorkTimeData {
  username: string;
  workTimeTicks: number;
  pauseTimeTicks: number;
}

export class WorkTaskSummaryReportGenerator {
  _workTaskSummary: WorkTaskSummary;
  _fontColor: string;
  /**
   *
   */
  constructor(workTaskSummary: WorkTaskSummary, fontColor: string = "#333333") {
    this._workTaskSummary = workTaskSummary;
    this._fontColor = fontColor;
  }
  /**
   * getPDFReport
   */
  public getPDFReport() {
    const workTimeString = this.getTimeSpanStringFromTicks(
      this._workTaskSummary.totalWorkDurationTicks
    );

    const pauseTimeString = this.getTimeSpanStringFromTicks(
      this._workTaskSummary.totalPauseLikeDurationTicks
    );

    const executionsCount = this._workTaskSummary.workExecutionSummaries.length;

    const doc = this.getPrintingDocumentContext();

    // header
    doc.setTextColor("#333333");
    doc.setFontSize(20).setFont("", "bold");

    doc.text(`Work task report - #${this._workTaskSummary.workTaskId}`, 10, 10);

    // general info
    doc.setFontSize(15).setFont("", "bold");

    doc.text("General info", 10, 20);

    doc.setFont("", "normal");

    doc.text(`Name: ${this._workTaskSummary.name}`, 10, 30);
    doc.text(`Work time: ${workTimeString}`, 10, 40);
    doc.text(`Pause time: ${pauseTimeString}`, 10, 50);
    doc.text(`Executions count: ${executionsCount}`, 10, 60);

    doc.setFont("", "bold");

    doc.text("Users time info", 10, 70);

    doc.setFont("", "normal");

    // table
    var usersWorkTimeData = this.getUsersWorkTimeData();

    var body = usersWorkTimeData.map((x) => [
      x.username,
      this.getTimeSpanStringFromTicks(x.workTimeTicks),
      this.getTimeSpanStringFromTicks(x.pauseTimeTicks),
    ]);

    autoTable(doc, {
      head: [["User", "Work time (hh:mm)", "Pause time (hh:mm)"]],
      margin: { top: 75, left: 10, right: 10, bottom: 10 },
      body,
    });

    doc.save("work-task-report.pdf");
  }

  /**
   * name
   */
  public getUsersWorkTimeData(): UserWorkTimeData[] {
    const result: UserWorkTimeData[] = [];

    this._workTaskSummary.workExecutionSummaries.forEach((x) => {
      if (result.some((utd) => utd.username === x.user?.username)) {
        const index = result.findIndex(
          (utd) => utd.username === x.user?.username
        );
        if (index !== -1) {
          result[index].workTimeTicks += x.totalWorkDurationTicks;
          result[index].pauseTimeTicks += x.totalPauseLikeDurationTicks;
        }
      } else {
        result.push({
          username: x.user?.username ?? "",
          workTimeTicks: x.totalWorkDurationTicks,
          pauseTimeTicks: x.totalPauseLikeDurationTicks,
        });
      }
    });

    return result;
  }

  private getPrintingDocumentContext(): jsPDF {
    return new jsPDF({
      orientation: "portrait",
      unit: "mm",
      compress: false,
      format: "a4",
    });
  }

  private getTimeSpanStringFromTicks(ticks: number): string {
    return TimeSpan.fromTicks(ticks).toHhMm();
  }
}
