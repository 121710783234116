import { useCallback } from "react";
import { MultiSelect } from "primereact/multiselect";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ColumnFilterElementTemplateOptions } from "primereact/column";
import { TableHeader } from "../../components/ui/table-header";
import { User } from "../../queries/models/user.model";
import { SelectItem } from "primereact/selectitem";
import { useTranslation } from "react-i18next";

interface Props {
  items: User[];
  roleItems: SelectItem[];
  onAddNewUser: () => void;
  selectedUser: User | undefined;
  onSelectUser: (user: User) => void;
}

export function UsersTableOverview({
  items,
  roleItems,
  onAddNewUser,
  selectedUser,
  onSelectUser,
}: Props) {
  const { t } = useTranslation();

  const roleOptionColumnFilterTemplate = useCallback(
    (options: ColumnFilterElementTemplateOptions) => {
      return (
        <MultiSelect
          options={roleItems}
          value={options.value}
          maxSelectedLabels={1}
          onChange={(e) => {
            options.filterApplyCallback(e.value, options.index);
            e.originalEvent.stopPropagation();
          }}
          placeholder={t("common.all")}
        />
      );
    },
    [roleItems, t]
  );

  return (
    <div className="h-full border-round-xl">
      <DataTable
        className="h-full"
        scrollable={true}
        scrollHeight="flex"
        value={items}
        filterDisplay="row"
        selectionMode="single"
        selection={selectedUser}
        onSelectionChange={(e) => onSelectUser(e.value as User)}
        header={
          <TableHeader
            header={t("common.users")}
            buttonLabel={t("common.add")}
            showButton
            onClick={onAddNewUser}
          />
        }
        emptyMessage={t("common.noResultsFound")}
      >
        <Column
          field="username"
          header={t("common.username")}
          sortable
          filter
          filterMatchMode="contains"
          filterPlaceholder={t("common.filter")}
          showFilterMenu={false}
          className="w-3"
        />
        <Column
          field="contactName"
          header={t("common.fullName")}
          sortable
          filter
          filterMatchMode="contains"
          filterPlaceholder={t("common.filter")}
          showFilterMenu={false}
          className="w-3"
        />
        <Column
          field="contactEmail"
          header={t("common.email")}
          style={{
            maxWidth: "200px",
            overflow: "clip",
            textOverflow: "ellipsis",
          }}
          sortable
          filter
          filterMatchMode="contains"
          filterPlaceholder={t("common.filter")}
          showFilterMenu={false}
          className="w-2"
        />
        <Column
          field="roleId"
          header={t("common.role")}
          className="w-2"
          body={(x) => x.role.name}
          sortable
          filterElement={roleOptionColumnFilterTemplate}
          filter
          filterMatchMode="in"
          showFilterMenu={false}
        />
      </DataTable>
    </div>
  );
}
