import { useMutation, useQuery } from "react-query";
import { Note } from "./models/note.model";
import { notesService } from "../services/notes.service";
import { AddNoteRequest } from "./models/add-note-request";

export function useGetNoteQuery(id: number | undefined) {
  return useQuery<Note>(["notes", id], () => notesService.getNote(id!), {
    refetchOnWindowFocus: false,
    enabled: id !== undefined,
  });
}

export function useAddNoteMutation() {
  return useMutation((request: AddNoteRequest) =>
    notesService.addNote(request)
  );
}

export function useDeleteNoteItemMutation() {
  return useMutation((noteItemId: number) =>
    notesService.deleteNoteItem(noteItemId)
  );
}
