import { useCallback, useMemo } from "react";
import { useInstallationsOptionsQuery } from "../../queries/installations.query";
import { Form, Formik } from "formik";
import { EnumExtensions } from "../../utils/enum-extensions";
import * as Yup from "yup";
import { EditContentHeader } from "../../components/ui/edit-content-header";
import FormikInputText from "../../components/ui/formik/FormikInputText";
import FormikMultiSelect from "../../components/ui/formik/FormikMultiSelect";
import { useToast } from "../../components/ui/toast-context-provider";
import { Asset } from "../../queries/models/asset.model";
import { CreateOrUpdateAsset } from "../../queries/models/create-or-update-asset.model";
import { Repetition } from "../../queries/models/enums/repetition.enum";
import FormikInputSwitch from "../../components/ui/formik/FormikInputSwitch";
import FormikDropDown from "../../components/ui/formik/FormikDropdown";
import { FormikQrCodes } from "../../components/ui/formik/FormikQrCodes";
import { useWarehousesAsSelectOptionsQuery } from "../../queries/warehouses.query";
import { Tag } from "../../queries/models/tag.model";
import { SelectItem } from "primereact/selectitem";
import { authService } from "../../services/auth.service";
import { AppFeatures } from "../../queries/models/enums/app-feature-enum";
import { useActiveAssetTypesQuery } from "../../queries/asset-type.query";
import { useTranslation } from "react-i18next";

export interface AssetFormProps {
  asset: Asset;
  isSubmitting?: boolean;
  tags: Tag[];
  onSave: (form: CreateOrUpdateAsset) => Promise<any>;
  onCancel: () => Promise<any> | void;
  onDelete: () => Promise<any> | void;
}

export function AssetForm({
  asset,
  tags,
  onSave,
  onCancel,
  onDelete,
}: AssetFormProps) {
  const { t } = useTranslation();
  const warehousesOptionsQuery = useWarehousesAsSelectOptionsQuery();
  const installationsOptionsQuery = useInstallationsOptionsQuery();
  const assetTypesQuery = useActiveAssetTypesQuery();
  const toast = useToast();
  const repetitionOptions = useMemo(
    () => EnumExtensions.getLabelAndValues(Repetition),
    []
  );

  const tagOptions = useMemo(() => {
    return tags
      ?.filter(
        (x) => x.isArchived === false || asset?.tags?.find((y) => y.id === x.id)
      )
      ?.map((x) => {
        return {
          label: x.name,
          value: x.id,
          extraValue: x,
        };
      });
  }, [tags, asset]);

  const assetTypeOptions = useMemo(() => {
    if (!assetTypesQuery.data) {
      return [];
    }

    return assetTypesQuery.data?.map((x) => {
      return {
        label: x.name,
        value: x.id,
        extraValue: x,
      };
    });
  }, [assetTypesQuery.data]);

  const hasWriteAccess = useMemo(() => {
    return authService.hasAccess(AppFeatures.WebAssetsWrite);
  }, []);

  const initialValues: CreateOrUpdateAsset = {
    name: asset?.name ?? "",
    description: asset?.description ?? "",
    serialNumber: asset?.serialNumber ?? "",
    isMaintenanceRequired: asset?.isMaintenanceRequired ?? false,
    repetition: asset?.repetition ?? Repetition.None,
    installationId: asset?.installationId,
    warehouseId: asset?.warehouseId,
    assetTypeId: asset?.assetTypeId,
    tagIds: asset?.tags?.map((x) => x.id) ?? [],
    id: asset?.id ?? 0,
    installationDate: asset?.installationDate,
    codes: asset.codes?.map((x) => x.code) ?? [],
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, t("alert.mustBe3CharactersOrMore"))
      .required(t("alert.required")),
    description: Yup.string().optional(),
    serialNumber: Yup.string().optional(),
    code: Yup.string().optional(),
    assetTypeId: Yup.number().required(t("alert.required")),
    warehouseId: Yup.number().required(t("alert.required")).nullable(),
    installationId: Yup.number().optional().nullable(),
    installationDate: Yup.date().optional().nullable(),
    tagIds: Yup.array().optional(),
    isMaintenanceRequired: Yup.boolean(),
    assignedInstallationIds: Yup.array().min(1, t("alert.required")),
    repetition: Yup.number().when(
      ["isMaintenanceRequired"],
      (isMaintenanceRequired, schema) => {
        return isMaintenanceRequired[0] === true
          ? schema.required(t("alert.required"))
          : schema.nullable().optional();
      }
    ),
    codes: Yup.array().optional(),
  });

  const tagTemplate = useCallback((option: SelectItem) => {
    return (
      <div className="p-clearfix bg-bg-red-100">
        <span>{option?.label}</span>
      </div>
    );
  }, []);

  const selectedTagTemplate = useCallback(
    (tagId: number) => {
      var tag = tags.find((x) => x.id === tagId);
      return (
        <div
          className="inline-block p-1 mr-1"
          style={{
            background: tag?.backgroundColor,
            color: tag?.textColor,
          }}
        >
          <span>{tag?.name}</span>
        </div>
      );
    },
    [tags]
  );

  const selectedAssetTypeTemplate = useCallback((option: any, props: any) => {
    var assetType = option?.extraValue;

    return (
      <div
        className="inline-block p-0 mr-0"
        style={{
          background: assetType?.backgroundColor,
          color: assetType?.textColor,
        }}
      >
        <span>{assetType?.name}</span>
      </div>
    );
  }, []);

  const assetTypeTemplate = useCallback((option: any) => {
    return (
      <div className="p-clearfix bg-bg-red-100">
        <span>{option?.label}</span>
      </div>
    );
  }, []);

  const onSubmit = useCallback(
    (values: CreateOrUpdateAsset) => {
      if (!hasWriteAccess) {
        return;
      }
      return onSave(values);
    },
    [hasWriteAccess, onSave]
  );

  return (
    <div className="p-2 h-full w-full overflow-auto">
      {!warehousesOptionsQuery.isFetching && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange
          validateOnMount
        >
          {(formik) => (
            <Form className="flex h-full flex flex-column">
              <EditContentHeader
                header={asset?.id ? asset.name : t("common.addNewAsset")}
                showDeleteButton={hasWriteAccess && !!asset.id}
                saveButtonDisabled={
                  !hasWriteAccess || !formik.dirty || !formik.isValid
                }
                onSaveClick={async () => {
                  if (!formik.isValid) {
                    toast.current?.show({
                      severity: "error",
                      detail: t("alert.formInvalid"),
                    });
                    return;
                  }
                  return formik.submitForm();
                }}
                onCancelClick={onCancel}
                onDeleteClick={onDelete}
              />
              <div className="formgrid grid">
                <div className="field col-12 md:col-6">
                  <FormikInputText
                    validationSchema={validationSchema}
                    label={t("common.name")}
                    name="name"
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <FormikInputText
                    label={t("common.serialNumber")}
                    name="serialNumber"
                    validationSchema={validationSchema}
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <FormikDropDown
                    label={t("common.warehouse")}
                    name="warehouseId"
                    validationSchema={validationSchema}
                    options={warehousesOptionsQuery.data}
                    filter
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <FormikDropDown
                    label={t("common.installation")}
                    name="installationId"
                    validationSchema={validationSchema}
                    options={installationsOptionsQuery.data}
                    filter
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-12">
                  <FormikInputText
                    label={t("common.description")}
                    name="description"
                    validationSchema={validationSchema}
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <FormikInputSwitch
                    checked={false}
                    label={t("common.isMaintenanceRequired")}
                    name="isMaintenanceRequired"
                    validationSchema={validationSchema}
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <FormikDropDown
                    label={t("common.repetition")}
                    name="repetition"
                    validationSchema={validationSchema}
                    options={repetitionOptions}
                    disabled={
                      !hasWriteAccess ||
                      formik.values.isMaintenanceRequired === false
                    }
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <FormikDropDown
                    label={t("common.type")}
                    name="assetTypeId"
                    validationSchema={validationSchema}
                    options={assetTypeOptions}
                    filter
                    itemTemplate={assetTypeTemplate}
                    valueTemplate={selectedAssetTypeTemplate}
                    disabled={!hasWriteAccess}
                  />
                </div>

                <div className="field col-12 md:col-8">
                  <FormikMultiSelect
                    label={t("common.tags")}
                    name="tagIds"
                    validationSchema={validationSchema}
                    options={tagOptions}
                    maxSelectedLabels={3}
                    itemTemplate={tagTemplate}
                    selectedItemTemplate={selectedTagTemplate}
                    display="chip"
                    disabled={!hasWriteAccess}
                  />
                </div>
                <div className="field col-12 md:col-12 mb-4">
                  <FormikQrCodes
                    label={t("common.codes")}
                    name="codes"
                    disabled={!hasWriteAccess}
                    validationSchema={validationSchema}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
