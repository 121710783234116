export class PrintingQrPage {
  format: "a3" | "a4" | "a5";
  orientation: "p" | "l";
  height: number;
  width: number;

  qrSize: number;
  qrMargin: number;
  leftMargin: number;
  topMargin: number;
  rightMargin: number;
  bottomMargin: number;

  constructor(
    f: "a3" | "a4" | "a5",
    h: number,
    w: number,
    o: "p" | "l",
    leftMargin: number,
    topMargin: number,
    rightMargin: number,
    bottomMargin: number,
    qrSize: number,
    qrMargin: number
  ) {
    this.format = f;
    this.orientation = o;
    this.height = h;
    this.width = w;

    this.leftMargin = leftMargin;
    this.topMargin = topMargin;
    this.rightMargin = rightMargin;
    this.bottomMargin = bottomMargin;
    this.qrMargin = qrMargin;
    this.qrSize = qrSize;
  }
}
